/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ion-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    --offset-top: 0px !important;
}

ion-item {
    margin-top: 15px;
}

ion-router-link {
    color: #131822;
}

ion-button {
    --background: #022550;
    text-transform: uppercase;
    color: #FFFFFF;
    --border-radius: 10px;
}

ion-button.client {
    --background: #9FB500;
    color: #FFFFFF;
}

ion-button.cancel,
ion-button.download {
    //--background: #7E1524;
    --background: #022550
}

ion-button.delete {
    --background: #7E1524;
}

ion-button.red-circle {
    --border-radius: 25px;
    --background: #D10014;
    margin-top: 0px;
}

/*
ion-button.mobile-length {
    width: 100%;
}
*/

ion-button.btn-Text {
    --color: #FFFFFF;
    text-transform: uppercase;
}

ion-label.label-title {
    text-transform: capitalize;
}

ion-title.cap-title {
    text-transform: capitalize;
}

ion-toolbar ion-buttons ion-button {
    //--background: white;
    --background: transparent;
}

i1on-checkbox {
    --checkmark-color: #FFFFFF !important;
    --background: #9FB500 !important;
    padding: 0px;
}

ion-checkbox {
    background: #9FB500;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 25px;
    border-color: #C5CDD6 !important;
    --size: 22px;
    --checkmark-width: 3px;
    --height: 25px;
    --width: 25px;
    --checkmark-color: #FFFFFF;
    --background-checked: #9FB500;
    --border-color: #C5CDD6 !important;
    --border-color-checked: #9FB500;
}

ion-icon.delete {
    color: #7E1524;
    cursor: pointer;
}

ion-icon.add {
    color: #9FB500;
    cursor: pointer;
}

.font-small {
    font-size: small;
}

.gray {
    color: #8C8F93;
}

.bg-gray {
    --background: #EEEEEE;
    background-color: #EEEEEE;
    margin-top: 0;
    padding-top: 0;
}

.white {
    color: #FFFFFF;
}

.bold {
    font-weight: bold;
}

.hidden {
    display: none;
}

.main-content {
    margin-bottom: 100px;
}

.black {
    color: black;
}

ion-textarea {
    --background: rgb(247, 247, 247);
}

a {
    text-decoration: none;
    color: black;
}

ion-toolbar.orange {
    --background: #C54F0A transparent;
    background: linear-gradient(to right, #C54F0A, #e48717);
}

ion-toolbar.lead {
    --background: #20566A transparent;
    background: linear-gradient(to right, #20566A, #438FA3);
}

ion-toolbar.plant {
    --background: #597A2E transparent;
    background: linear-gradient(to right, #597A2E, #92B25B);
}

ion-toolbar.hazards {
    --background: #249671 transparent;
    background: linear-gradient(to right, #249671, #4BC7AA);
}

ion-toolbar.task_risk {
    --background: #320B94 transparent;
    background: linear-gradient(to right, #320B94, #611AC6);
}

ion-toolbar.incidents {
    --background: #9C0D0D transparent;
    background: linear-gradient(to right, #9C0D0D, #cc1f1f);
}

ion-toolbar.plannings {
    --background: #a044b0 transparent;
    background: linear-gradient(to right, #a044b0, #dc84cc);
}

ion-toolbar.ccms {
    --background: #852F99 transparent;
    background: linear-gradient(to right, #852F99, #BB5CC9);
}

ion-toolbar.kpis {
    --background: #9EB403 transparent;
    background: linear-gradient(to right, #9EB403, #CDDB07);
}

ion-toolbar.observations {
    --background: #e1aa00 transparent;
    background: linear-gradient(to right, #e1aa00, #ffd145);
}

ion-toolbar.laggingIndicators {
    --background: #5c1010ff transparent;
    background: linear-gradient(to right, #5c1010ff, rgb(136, 76, 76));
}

.page-header {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    font-weight: bold;
}

.page-header>h1 {
    float: left;
    margin-top: 10px;
    /* important alignment */
    width: 15%;
    width: auto;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 24px;
    //font-weight: bold;
}

.page-header-wrapper {
    display: block;
    float: right;
}

.clickable {
    cursor: pointer;
}

ion-item-divider {
    border-color: black;
}

.modal-class .modal-wrapper {
    height: 250px;
}

.modal-class-lagging .modal-wrapper {
    height: 300px;
}

ion-modal {
    --height: auto;
}

a.underlined {
    text-decoration: underline;
}

.popover-style {
    --min-width: 30%;
    --max-width: 90%;
    --width: auto !important;
}

.popover-large {
    --min-width: 80%;
    --min-height: 80%;
}

.wide-popover .popover-content {
    --min-width: 80%;
    --min-height: 70%;
}

.tooltip-style .popover-content {
    --width: 400px;
}

.tooltip-style-full .popover-content {
    --width: 1024px;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ios {
    ion-list-header {
        font-size: 14px;
    }

    ion-list-header ion-label {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.md {
    ion-list-header {
        font-size: 14px;
    }
}

.wide-label {
    width: 40px !important;
    border: 1px solid red;
}

ion-label.select-label {
    min-width: 90px;
    max-width: 90px;
}

ion-select {
    max-width: 65% !important;
    min-width: 65% !important;
    text-align: right;
    padding-left: 0;
    margin-left: 0;
}

::ng-deep .wide-popover {
    --width: 95%;
    --max-width: 768px;
}

::ng-deep .wide-popover ion-label {
    white-space: pre-line !important;
}

.no-bottom {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

ion-item-divider.thin {
    min-height: 1px !important;
    max-height: 1px !important;
    margin-top: 5px;
}

ion-item-divider.thin .item-inner {
    min-height: 1px !important;
    max-height: 1px !important;
}

.float-right {
    float: right;
}

.report-header {
    border-bottom: 1px solid #CCCCCC;
    margin: 20px 15px 10px 10px;
}

.report-header ion-label {
    padding-bottom: 5px;
}

:host ::ng-deep .pie-label {
    font-size: 30px;
    border: 1px solid red;
}

.pointer {
    cursor: pointer;
}

p.no-results {
    padding: 10px;
}

.no-padding {
    padding: 0;
}

g.line-chart>g:last-of-type>g:nth-child(n) g.line-series>path {
    stroke-width: 3;
    stroke-linecap: round;
}

.email-alert .alert-wrapper {
    min-width: 470px !important;
}

ion-list.listing ion-label {
    margin-right: 0px;
}

ion-list.listing ion-label:not(:first-child) {
    margin-left: 20px;
}

// @media print styles are included to improve layout and content when printing in the web application.
@media print {
    // Within this print style, contain overrides are needed for reliable rendering in browsers that support it (Chrome).
    //  contain is set by Ionic for several components. Ionic sets contain based on assumptions about content (e.g. the
    //  size of child content cannot affect the height of the parent container. These assumptions no longer hold true
    //  for the CSS used during printing. If we do not set contain to none, the browser does not think the content
    //  can change, so it does not even consider rendering the new styles and layouts.

    // Certain content (e.g. headers and footers) are not useful in printed material. These objects also negatively affect
    //  the layout of certain content. In many cases they appear on top of the "main" content.
    ion-header,
    ion-backdrop,
    .tabbar,
    ion-footer {
        display: none !important;
    }

    // ion-nav needs to be treated special compared to the containers below. It is possible for an ion-nav to be present
    //  at a similar level to the container of the modal (ion-modal). If the position of ion-nav is set to relative it
    //  will always be displayed before the modal content. This causes the modal content to come after the ion-nav
    //  content, which is undesirable in all situations I've tested.
    ion-nav {
        contain: none;
        overflow: visible;
    }

    // When printing, we want to treat an active modal or page as  "full" content. We allow overflow and let the content
    //  determine the height of the container. We need to ensure the content as well as the content containers are
    //  updated. If a modal is active, the modal will be displayed in the printed content. Worth noting: using .app-root
    //  appears to cover ion-app, so ion-app is not included directly. Also, the items have been sorted based on scope.
    .scroll-content,
    ion-modal.show-page,
    ion-modal.show-page .modal-wrapper,
    ion-modal.show-page .ion-page.show-page,
    ion-modal.show-page .ion-page.show-page>ion-content,
    .ion-page.show-page,
    .ion-page.show-page>ion-content,
    ion-tab,
    ion-tabs,
    .app-root,
    body {
        //border: 10px solid green;
        //margin: 0px 40px 0px 40px;
        contain: none;
        position: relative;
        height: 100% !important;
        overflow: visible;
    }

    // When we are printing, we want to remove the default margin-top. The margin-top is needed so that scrollable content
    //  is not hidden under the nav bar at the top of the page. When printing, the nav bar is not included, so the margin
    //  is not needed.
    .fixed-content,
    .scroll-content {
        margin-top: 0 !important;
    }

    #printableArea .filters>ion-row>ion-col:last-child ion-row,
    #printableArea .filters>ion-row>ion-col:last-child ion-row ion-col {
        padding-right: 0px !important;
        margin-right: 0px !important;
        //border: 1px solid red;
    }

    #printableArea .filters>ion-row>ion-col:last-child ion-row ion-col {
        width: 100% !important;
    }

    //#printableArea .filters ion-col.report-header {
    //    padding-right: 0px;
    //    border: 1px solid red;
    //}

    #printableArea .report-record {
        margin-top: 0px;
    }

    ion-text {
        white-space: pre-wrap;
    }

    //#printableArea div.pdfTable {
    //    break-after: always;
    //}
}

@media print {
    img {
        page-break-before: auto;
        /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto;
        /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid;
    }
}

@media print {

    img {

        page-break-before: auto;
        /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */

        page-break-after: auto;
        /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */

        page-break-inside: avoid;

    }

}
.select-icon {
    color: #fff !important;
}