// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$grid-breakpoints: (
 xs: 0, // Phone (0px - 575px)
 sm: 576px, // Phablet (576px - 767px)
 md: 768px, // Tablet vertical (768px - 991px)
 lg: 992px // Tablet horizontal, Desktop (992px and above)
);

$grid-max-widths: (
 lg: 992px
);

ion-header .toolbar {
  margin: auto;
 }
 
 @media (min-width: map-get($grid-breakpoints, lg)) {
  ion-navbar.toolbar,
  ion-header .toolbar[fixed],
  ion-footer .toolbar[fixed],
  ion-tabs .tabbar {
    margin: 0;
    padding-right: calc((100% - #{map-get($grid-max-widths, lg)}) / 2);
    padding-left: calc((100% - #{map-get($grid-max-widths, lg)}) / 2);
  }
 }

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=916ae73e-3dc6-4f05-866f-1cadcafd6ddc");
    @font-face{
        font-family:"Swiss721 BT W01 Thin";
        src:url("../assets/Fonts/d775996c-c32b-4f91-b5bd-dc651ee61e8e.woff2") format("woff2"),url("../assets/Fonts/8526f293-ef46-45e7-95fa-9ec6ad6b7743.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Light";
        src:url("../assets/Fonts/8bce9cb8-d4f7-4bcd-b2f9-48863565492f.woff2") format("woff2"),url("../assets/Fonts/26a8f5d8-621d-4416-937e-e4a1cfda93ff.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Regular";
        src:url("../assets/Fonts/195b0c1b-165f-4bd1-b23f-cbb6a9a3aa24.woff2") format("woff2"),url("../assets/Fonts/89828f9b-7a7b-4e76-b757-541f47fe07a3.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Medium";
        src:url("../assets/Fonts/ee7305d5-3db9-4e87-8813-e68c0a6bd835.woff2") format("woff2"),url("../assets/Fonts/552709be-4bfe-4b7c-94a3-d94f54edf163.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Bold";
        src:url("../assets/Fonts/60a6c625-19b1-4df2-8635-49ba40161273.woff2") format("woff2"),url("../assets/Fonts/921ee353-9901-4b98-9554-c2412566e2c7.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Heavy";
        src:url("../assets/Fonts/ac883e1e-1e6b-4e7f-b1d7-f45b49b2cbe4.woff2") format("woff2"),url("../assets/Fonts/dd87a06f-d70b-4c75-8f26-b0ee3f5ba10c.woff") format("woff");
    }
    @font-face{
        font-family:"Swiss721 BT W01 Black";
        src:url("../assets/Fonts/bc614c2f-da64-451b-b50a-210f60e6f99b.woff2") format("woff2"),url("../assets/Fonts/f250d7fa-0fcd-44a1-9753-d8d85eaf3f05.woff") format("woff");
    }
